import { addToast } from '@iheartradio/web.accomplice/toast';
import type { PresetKeys, PresetsTypes } from '@iheartradio/web.api/amp';
import { isUndefined } from '@iheartradio/web.utilities';
import type { Key } from 'react-stately';
import type { z } from 'zod';

import type { Preset } from '~app/routes/_app/_index/components/presets-carousel';

import type { Presets } from './presets-storage';
import { PresetsStorage } from './presets-storage';

export const ContentTypes = {
  ARTIST: 'artist',
  COLLECTION: 'playlist',
  FAVORITES: 'station',
  LIVE: 'station',
  PODCAST: 'podcast',
};

export type PresetIndex = (typeof PresetKeys)['_input'];

export const persistToStorage = (data: Presets, callback?: () => void) => {
  // Clear all items from memory storage
  Array.from({ length: 15 }, (_, i) => i).map(i =>
    PresetsStorage.remove(String(i) as PresetIndex),
  );

  // Save fresh new data to storage
  PresetsStorage.serialize(data, callback);
};

export function addPreset({
  id,
  title,
  type,
  position,
}: {
  id: Key;
  title: string;
  type: z.infer<typeof PresetsTypes>;
  position?: PresetIndex;
}) {
  const presets = PresetsStorage.deserialize();
  const keys = Object.keys(presets);
  const presetsLength = keys.length;

  // If presets is full -- error
  if (presetsLength === 15) {
    addToast({
      kind: 'error',
      text: `Your presets are full. Remove one to add this ${ContentTypes[type]}`,
    });
    return { success: false };
  }

  const itemAlreadyExists = Object.values(presets).find(preset => {
    return preset.id === id && preset.type === type;
  });

  // If specific item already exists within user's presets data -- error
  if (itemAlreadyExists) {
    addToast({
      kind: 'error',
      text: `${title} has already been added to your presets`,
    });
    return { success: false };
  }

  // If a position is not specified...
  if (!position) {
    // Find first available index - i.e. [0,1,2,4,7]: First available would be "3"
    const availableIndex = Array.from({ length: 15 }, (_, i) => i).find(
      (_, index) => !keys.includes(String(index) as PresetIndex),
    );

    // If a valid index was found, save to storage
    if (!isUndefined(availableIndex)) {
      persistToStorage(
        {
          ...presets,
          [String(availableIndex)]: { id, type, title },
        },
        () => addToast({ kind: 'success', text: `${title} added to presets` }),
      );

      return { success: true };
    }
  } else if (position && !keys.includes(position)) {
    // If specific position is provided and that position isn't filled in presets data, save to storage
    persistToStorage(
      {
        ...presets,
        [String(position)]: { id, type, title },
      },
      () => addToast({ kind: 'success', text: `${title} added to presets` }),
    );

    return { success: true };
  } else if (position && keys.includes(position)) {
    // If specific position is provided and that position is already filled in presets data -- error
    addToast({
      kind: 'error',
      text: `There was an issue adding ${title} to your presets. Please try again`,
    });
    return { success: false };
  }

  return { success: false };
}

export function deletePreset({
  title,
  position,
}: {
  title: string;
  position: PresetIndex;
}) {
  const presets = PresetsStorage.deserialize();
  const keys = Object.keys(presets);
  // If the position provided is occupied in the preset data...
  if (keys.includes(position)) {
    // Delete item from that position and save to storage
    delete presets[position];
    persistToStorage(presets, () =>
      addToast({ kind: 'success', text: `${title} removed from presets` }),
    );

    return { success: true };
  } else {
    addToast({
      kind: 'error',
      text: `There was an issue removing ${title} from your presets. Please try again.`,
    });

    return { success: false };
  }
}

export function movePreset(presetsData: Array<Preset>) {
  const presetsObj = {} as Record<PresetIndex, Preset>;

  // Format data into object for memory storage and AMP:
  // Loop through array of `presetsData` and add non-empty objects to `presetsObj`
  for (const [index, preset] of presetsData.entries()) {
    if (preset.imageUrl) {
      presetsObj[String(index) as PresetIndex] = preset;
    }
  }

  persistToStorage(presetsObj as Presets);
}
