import { addToast } from '@iheartradio/web.accomplice/toast';
import type { ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';
import { prop } from 'remeda';

import { amp } from '~app/api/amp-client';

import type { Presets } from './presets-storage';
import { PresetsStorage } from './presets-storage';
import type { PresetIndex } from './utils';
import { addPreset, deletePreset, movePreset, persistToStorage } from './utils';

// Create an array that has 15 objects where the objects will be:
// 1. An empty placeholder
// 2. The preset object
// This ensures the <PresetsCarousel /> will always have 15 cards
const mapPresets = (presetData?: Presets) => {
  return Array.from({ length: 15 }, (_, i) => {
    const index = String(i) as PresetIndex;

    if (presetData?.[index]) {
      return { ...presetData[index]!, index };
    }
    return { id: index, index };
  });
};

export const PresetsContext = createContext<ReturnType<typeof mapPresets>>([]);

export const usePresets = () => {
  return {
    addPreset,
    deletePreset,
    movePreset,
  };
};

const fetchPresets = () =>
  amp.api.v3.profiles.getPresets().then(prop('body')).then(prop('presets'));

const putPresets = (presets: Presets) =>
  amp.api.v3.profiles
    .putPresets({ body: { presets } })
    .then(() => fetchPresets());

export const PresetsProvider = ({ children }: { children: ReactNode }) => {
  const [presets, setPresets] = useState<ReturnType<typeof mapPresets>>(() =>
    mapPresets(),
  );

  useEffect(() => {
    // On initial mount, fetch presets data and save to state
    fetchPresets()
      .then(data => {
        if (data) {
          setPresets(() => mapPresets(data));
          persistToStorage(data);
        }
        return data;
      })
      .catch(() => {
        addToast({ kind: 'warning', text: 'Failed to fetch presets' });
      });

    // Subscribe to PresetsStorage's `serialize` method,
    // When `serialize` is called, PUT that new data to amp and then GET the presets data
    return PresetsStorage.subscribe({
      serialize: async (_, data, callback) => {
        const presets = Object.entries(data).reduce((acc, [key, value]) => {
          if (value) {
            const { id, type } = value;
            return { ...acc, [key]: { id, type } };
          } else {
            return acc;
          }
        }, {} as Presets);

        try {
          const newPresets = await putPresets(presets);

          // Save fetched preset data to state
          setPresets(() => mapPresets(newPresets));
          callback?.();
        } catch {
          addToast({
            kind: 'warning',
            title: 'Oops!',
            text: 'Your presets are saved to the browser, but we failed to synchronize them to the server. This data may be lost in future sessions.',
          });
        }
      },
    });
  }, []);

  return (
    <PresetsContext.Provider value={presets}>
      {children}
    </PresetsContext.Provider>
  );
};
